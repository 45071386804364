<!--
 * @Description: Svg通用组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-28 14:23:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 17:41:11
-->
<template>
  <!-- aria-hidden, 帮助残障人士阅读(设备读取内容时会跳过这个标签以免混淆) -->
  <svg :class="svgClass"
       aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
import { computed } from '@vue/runtime-core'
export default {
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    iconClass: {
      type: String
    }
  },
  setup (props, ctx) {

    const iconName = computed(() => `#icon-${props.iconName}`)
    const svgClass = computed(() => 'svg-icon ' + (props.className ? props.className : ''))
    
    return {
      iconName,
      svgClass,
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  stroke: currentColor;
  overflow: hidden;
}
</style>