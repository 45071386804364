<!--
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-04 11:12:47
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 16:15:10
-->
<template>
  <div id="app">
    <div v-if="spinning && !cancelSpinning" class="spin">
      <a-spin size="large"></a-spin>
    </div>
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { ref, watch } from 'vue';
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const locale = ref(zhCN);
    const spinning = ref(false);
    const cancelSpinning = ref(false)
    watch(() => store.state.spinning, (cur, pre) => {
      spinning.value = cur
    })
    watch(() => store.state.cancelSpinning, (cur, pre) => {
      cancelSpinning.value = cur
    })
    // 这里执行的时候，html里的立即执行函数还没有执行
    if (window._gbStatisticsConfig) {
      // 每次获取用户信息都会更新用户id
      window._gbStatisticsConfig.push([
        '_setUrl',
        process.env.VUE_APP_statisticsURL,
      ]);
      console.log(
        'process.env.VUE_APP_statisticsURL1111',
        process.env.VUE_APP_statisticsURL
      );
    }
    return {
      locale,
      spinning,
      cancelSpinning,
    };
  },
};
</script>

<style lang="less">
#app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .spin {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.5);
  }
}
</style>
