/*
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-04 11:12:47
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 16:16:54
 */
import { createStore } from "vuex";

export default createStore({
  state: {
    spinning: false, // 是否显示加载动画
    cancelSpinning: false, // 用于取消加载动画
    curEditingQuestonId: '', // 当前编辑的题目
    filterChoices: [],
  },
  mutations: {
    changeSpinning(state, bool) {
      state.spinning = bool
    },
    changeCancelSpinning(state, bool) {
      state.cancelSpinning = bool
    },
    changeCurEditQuestionId(state, id) {
      state.curEditingQuestonId = id
      console.log('当前编辑的题目为:', state.curEditingQuestonId)
    },
    changeFilterChoices(state, choices) {
      state.filterChoices = choices
    }
  },
  actions: {},
  modules: {}
});
