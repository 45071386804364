/*
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-04 11:12:47
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 15:24:22
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import antd from 'ant-design-vue/es';
import "ant-design-vue/dist/antd.css";
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import SvgIcon from "@/components/icons/SvgIcon";
import '@/assets/icons'

createApp(App)
  .use(store)
  .use(router)
  .use(antd)
  .use(VueClipboard)
  .component('svg-icon', SvgIcon) // global registration - can be used anywhere
  .mount("#app");

