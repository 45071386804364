/*
 * @Description: 
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-28 14:37:06
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-28 17:48:02
 */

// 定义一个加载目录的函数
const requireAll = requireContext => requireContext.keys().map(requireContext);
/**
 * require.context是什么？
 * 一个webpakc的api，通过执行require.context函数获取一个上下文
 * 主要用来实现自动化导入模块
 * 在前端工程中，如果遇到一个文件夹引入很多模块的情况
 * 可以使用这个api，它会遍历文件夹中的指定文件
 * 然后自动导入，使得不需要每次显示的调用import导入模块
 * 
 * 它接受三个参数
 *  要搜索的文件夹目录
 *  是否还应该搜索它的子目录，
 *  以及一个匹配文件的正则表达式。
 */
const req = require.context("./svg", false, /\.svg$/);
// 加载目录下的所有 svg 文件
requireAll(req);