/*
 * @Description: 路由
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-04 11:12:47
 * @LastEditors: PSG
 * @LastEditTime: 2021-06-21 10:39:19
 */
import { createRouter, createWebHashHistory } from "vue-router"

const routes = [{
    path: '/',
    component: () => import("@/components/layout/index"),
    children: [{
      path: "",
      redirect: '/personal'
    }, {
      path: '/recycleBin',
      meta: {
        title: '回收站'
      },
      component: () => import("@/views/recycleBin"),
    }]
  }, {
    path: "/login",
    name: "Login",
    meta: {
      title: '登录'
    },
    component: () => import("@/views/account/Login"),
  }, {
    path: "/personal",
    name: "Personal",
    meta: {
      title: '个人中心'
    },
    component: () => import("@/views/personal"),
  }, {
    path: "/template",
    name: "Template",
    meta: {
      title: '问卷模板'
    },
    component: () => import("@/views/template"),
  }, {
    path: "/edit/",
    name: "Edit",
    meta: {
      title: '问卷编辑主页'
    },
    component: () => import("@/views/edit"),
    children: [{
      path: "questionEdit/:id",
      name: "QuestionEdit",
      meta: {
        title: '问卷编辑'
      },
      component: () => import("@/views/edit/questionEdit"),
    }, {
      path: "questionSetting/:id",
      name: "QuestionSetting",
      meta: {
        title: '问卷设置'
      },
      component: () => import("@/views/edit/questionSetting"),
    }]
  }, {
    path: "/preview/:id",
    name: "Preview",
    meta: {
      title: '问卷预览'
    },
    component: () => import("@/views/preview") 
  }, {
    path: "/exercise/:id",
    name: "Exercise",
    meta: {
      title: '问卷填写'
    },
    component: () => import("@/views/exercise") 
  }, {
    path: "/success",
    name: "Success",
    meta: {
      title: '问卷提交成功'
    },
    component: () => import("@/views/exercise/success") 
  }, {
    path: "/invalid",
    name: "Invalid",
    meta: {
      title: '问卷链接无效'
    },
    component: () => import("@/views/exercise/invalid") 
  }, {
    path: "/mobile/failure",
    name: "MobileFailure",
    meta: {
      title: '手机端问卷失败'
    },
    component: () => import("@/views/exercise/mobile/failure") 
  }, {
    path: "/detail/:id",
    name: "Detail",
    meta: {
      title: '问卷所有信息总览'
    },
    component: () => import("@/views/detail"),
    children: [{
      path: "editQuestion",
      name: "EditQuestion",
      meta: {
        title: '问卷编辑'
      },
      component: () => import("@/views/detail/editQuestion"),
    }, {
      path: "dispatchQuestion",
      name: "DispatchQuestion",
      meta: {
        title: '问卷派发'
      },
      component: () => import("@/views/detail/dispatchQuestion"),
    }, {
      path: "recycleBin",
      name: "RecycleBin",
      meta: {
        title: '回收情况'
      },
      component: () => import("@/views/detail/recycleBin"),
      children: [{
        path: "answerDetails",
        name: "AnswerDetails",
        meta: {
          title: '答卷详情'
        },
        component: () => import('@/views/detail/recycleBin/answerDetails')
      }, {
        path: "channelAnalysis",
        name: "ChannelAnalysis",
        meta: {
          title: '渠道分析'
        },
        component: () => import('@/views/detail/recycleBin/channelAnalysis')
      }]
    }, {
      path: "analysis",
      name: "Analysis",
      meta: {
        title: '统计分析'
      },
      component: () => import("@/views/detail/analysis"),
    }],
  }, {
    path: "/noPermission",
    name: "NoPermission",
    meta: {
      title: '无权访问提示页'
    },
    component: () => import("@/views/common/noPermission"),
  }, {
    path: "/modal",
    name: "Modal",
    meta: {
      title: '测试页面'
    },
    component: () => import("@/views/account/Test1"),
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from, next) => {
//   const isLogin = localStorage.isLogin;
//   (isLogin || to.name === 'Login' || to.name === 'Test' || to.name === 'Personal') ? next() : next({name: 'Login'})
// })
export default router
